<template>
  <div v-html="this.conditions"></div>
</template>

<script>
export default {
  name: "Conditions",

  computed: {
    config() {
      return this.$store.getters["config/config"] || {};
    },

    conditions() {
      return (this.config && this.config.conditions) || "";
    },
  },
};
</script>
